import * as bootstrap from "bootstrap";
import { Swiper, Pagination, Autoplay, Navigation } from 'swiper';
import * as timeago from 'timeago.js';
import videojs from 'video.js';

window.timeago = timeago;

Swiper.use([Pagination, Autoplay, Navigation]);

/* On Load
**************************************************************/
window.addEventListener('DOMContentLoaded', () => {
    initCore();
    init();
    // fontSizeSwitcher();
});

window.addEventListener('load', () => {
    refresh();
});

/* On Resize
**************************************************************/
window.addEventListener('resize', refresh);


/* On Resize
**************************************************************/
window.addEventListener("scroll", initCore);

function refresh() {
    var mobile = window.matchMedia('(min-width: 0px) and (max-width: 992px)');
    var desktop = window.matchMedia('(min-width: 993px)');

    responsive(mobile, desktop);
    // swiperMode(mobile, desktop);
    autocollapse(mobile, desktop);
    navdropdown(mobile, desktop);
}

function autocollapse(mobile, desktop) {
    var navContanier = document.getElementById('main_nav');
    var nav = document.getElementById('nav_list');
    var maxHeight = 60;
    var navHeight = navContanier.clientHeight;
    if (navHeight >= maxHeight && desktop.matches) {
        nav.querySelector('#nav-dropdown').classList.remove('d-none');
        while (navHeight > maxHeight) {
            //  add child to dropdown
            var children = nav.querySelectorAll(':scope > li:not(:last-child)');
            var count = children.length;
            var link = children[count - 1].children[0];
            link.classList.add('dropdown-item', 'text-end');
            link.classList.remove('nav-link')

            nav.querySelector(':scope > li:last-child ul').prepend(children[count - 1]);

            navHeight = navContanier.clientHeight;
        }
    }
    else {
        var menu = nav.querySelector(':scope > li:last-child ul')
        var collapsed = menu.querySelectorAll(':scope > li');
        while ((navHeight < maxHeight || mobile.matches) && collapsed.length > 0) {
            //  remove child from dropdown     
            var link = collapsed[0].children[0];
            link.classList.remove('dropdown-item', 'text-end');
            link.classList.add('nav-link')
            nav.insertBefore(collapsed[0], nav.querySelector(':scope > li:last-child'))
            collapsed = menu.querySelectorAll(':scope > li');
            navHeight = navContanier.clientHeight;
        }
        if (collapsed.length === 0) {
            nav.querySelector('#nav-dropdown').classList.add('d-none');
        }

        if (navHeight > maxHeight && desktop.matches) {
            autocollapse(mobile, desktop);
        }
    }
};

function navdropdown(mobile, desktop) {
    // make it as accordion for smaller screens
    if (mobile.matches) {
        // close all inner dropdowns when parent is closed
        document.querySelectorAll(".navbar .dropdown").forEach(function (everydropdown) {
            everydropdown.addEventListener("hidden.bs.dropdown", function () {
                // after dropdown is hidden, then find all submenus
                this.querySelectorAll(".submenu").forEach(function (everysubmenu) {
                    // hide every submenu as well
                    everysubmenu.style.display = "none";
                });
            });
        });

        document.querySelectorAll(".dropdown-menu a").forEach(function (element) {
            element.addEventListener("click", function (e) {
                let nextEl = this.nextElementSibling;
                if (nextEl && nextEl.classList.contains("submenu")) {
                    // prevent opening link if link needs to open dropdown
                    e.preventDefault();
                    if (nextEl.style.visabilty == "block") {
                        nextEl.style.display = "none";
                    } else {
                        nextEl.style.display = "block";
                    }
                }
            });
        });
    }
    // end if innerWidth
};

function responsive(mobile, desktop) {
    const weatherContainer = document.getElementById('weather_holder')
    if (mobile.matches && weatherContainer.children.length > 0) {
        var mobileHolder = document.getElementById('mobile_nav_holder');
        mobileHolder.prepend(document.getElementById('weather'))
        //mobileHolder.prepend(document.getElementById('header_btn'))
    } else if (desktop.matches && weatherContainer.children.length == 0) {
        document.getElementById('header_btn_holder')?.append(document.getElementById('header_btn'))
        weatherContainer.append(document.getElementById('weather'))
    }
}

// function fontSizeSwitcher() {
//     const fsup = document.getElementById('font-size-up')
//     const fsdown = document.getElementById('font-size-down')
//     if (fsup && fsdown) {
//         const fzs = [80, 100, 115, 125, 150]
//         const fs = document.getElementById('font-size');
//         const content = document.querySelector('.content');
//         const elements = []


//         const changefontsize = (size) => {
//             elements.forEach(x => {
//                 x.el.style.fontSize = Math.ceil(parseFloat(x.basefs) * (size / 100)) + 'px';
//                 x.el.style.lineHeight = Math.ceil(parseFloat(x.baselh) * (size / 100)) + 'px'
//             })
//         }

//         const getCurrentfsIndex = () => {
//             const size = fs.innerText;
//             const sizeAsInt = parseInt(size.slice(0, -1))
//             return fzs.indexOf(sizeAsInt);
//         }


//         content.querySelectorAll('*').forEach(x => {
//             const elStyle = window.getComputedStyle(x, null);
//             elements.push({
//                 el: x,
//                 basefs: elStyle.getPropertyValue('font-size'),
//                 baselh: elStyle.getPropertyValue('line-height')
//             })
//         })

//         fsup.addEventListener('click', function () {
//             const index = getCurrentfsIndex();
//             if (index == fzs.length - 1)
//                 return;

//             fs.innerText = fzs[index + 1] + "%";
//             changefontsize(fzs[index + 1])
//         })

//         fsdown.addEventListener('click', function () {
//             const index = getCurrentfsIndex();
//             if (index == 0)
//                 return;

//             fs.innerText = fzs[index - 1] + "%";
//             changefontsize(fzs[index - 1])
//         })
//     }
// }

function initCore() {
    if (window.scrollY > 60) {
        document.getElementById("navbar_top").classList.add("fixed-top");
        // add padding top to show content behind navbar
        var navbar_height = document.querySelector(".navbar").offsetHeight;
        document.body.style.paddingTop = navbar_height + "px";
    } else {
        document.getElementById("navbar_top").classList.remove("fixed-top");
        // remove padding top from body
        document.body.style.paddingTop = "0";
    }

    if (window.scrollY > 600) {
        document.querySelector(".btn-scroll-top").classList.add("show")
    } else {
        document.querySelector(".btn-scroll-top").classList.remove("show")
    }
}

function init() {
    // document.querySelector('[data-awro-backtop]').addEventListener('click', function (e) {
    //     e.preventDefault()
    //     document.body.scrollTop = 0; // For Safari
    //     document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // })

    // const videoContainer = document.querySelector('.video-player');
    // if (videoContainer) {
    //     videoContainer.querySelector('[data-awro-colse]')?.addEventListener('click', function (e) {
    //         videojs.getPlayer(videoContainer.querySelector('video')).pause();
    //         videoContainer.style.display = 'none';
    //     })
    // }

    // document.querySelector('.breaking-news')?.addEventListener('closed.bs.alert', refresh);

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    // var videoSwiper = new Swiper(".video-swiper", {
    //     autoplay: {
    //         delay: 5000,
    //     },
    //     navigation: {
    //         nextEl: '.swiper-button-next',
    //         prevEl: '.swiper-button-prev',
    //     },
    // })

    var initSwiperMqrquee = function () {
        const index = this.activeIndex;
        this.slides.forEach((x, i) => {
            x.classList.remove('marquee')

            if (i == index && x.clientWidth < x.scrollWidth)
                x.classList.add('marquee')
        })
    }

    var newsSwiperOptions = {
        effect: "fade",
        autoplay: {
            delay: 20000
        },
        pagination: {
            el: '',
            type: 'bullets',
            clickable: true,
        },
    }

    newsSwiperOptions.pagination.el = '.breaking-news-pagination';
    var breakingNewsSwiper = new Swiper(".breaking-news", { ...newsSwiperOptions });
    breakingNewsSwiper.on('afterInit', initSwiperMqrquee);
    breakingNewsSwiper.on('slideChange', initSwiperMqrquee);


    // newsSwiperOptions.pagination.el = '.breaking-news-pagination';
    // newsSwiperOptions.autoplay.delay = 4000;
    // var breakingNewsSwiper = new Swiper(".breaking-news-swiper", { ...newsSwiperOptions });


    var newsSwiper = new Swiper("[data-swiper='news']", {
        speed: 600,
        slidesPerView: 'auto',
        spaceBetween: 25,
        freeMode: true,
        // watchSlidesProgress: !0,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
    newsSwiper.on('activeIndexChange', (swiper) => {
        if (swiper.isBeginning)
            swiper.el.classList.add('hide-start')
        else if (swiper.isEnd)
            swiper.el.classList.add('hide-end')
        else {
            swiper.el.classList.remove('hide-start')
            swiper.el.classList.remove('hide-end')
        }
    })


    // new Swiper(".upcoming-programs-swiper", {
    //     slidesPerView: 1,
    //     spaceBetween: 24,
    //     breakpoints: {
    //         1023: {
    //             slidesPerView: 4
    //         },
    //         576: {
    //             slidesPerView: 2
    //         }
    //     },
    //     pagination: {
    //         el: '.swiper-pagination',
    //         type: 'bullets',
    //         clickable: true,
    //     },
    // });

    new Swiper(".footer-tags-swiper", {
        slidesPerView: 'auto',
        spaceBetween: 10,
        freeMode: true,
    });

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation')

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
        .forEach(function (form) {
            form.addEventListener('submit', function (event) {
                if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)
        })

    // const urlParams = new URLSearchParams(window.location.search);
    // const param = urlParams.get('fn');
    // if (param) {
    //     setTimeout(() => {
    //         const el = document.getElementById(param)
    //         const y = el.getBoundingClientRect().top + window.pageYOffset - 150;
    //         window.scrollTo({ top: y, behavior: 'smooth' });
    //     }, 300);
    // }
}
